import styled from "@emotion/styled"

const ImageBackground = styled.div`
  height: ${props => (props.height ? props.height : "240px")};
  width: 100%;
  background-image: url(${props => (props.src ? props.src : "")});
  background-size: cover;
  background-repeat: no-repeat;
`

export default ImageBackground
