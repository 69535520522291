import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ImageBackground from "../../components/ImageBackground";

import imgClass01 from "../../images/slides/music-republicain-4.jpg";
import imgClass02 from "../../images/slides/music-republicain-1.jpg";
import imgClass03 from "../../images/slides/music-republicain-2.jpg";
import imgClass04 from "../../images/our-classes/our-classes-4.jpg";
import imgClass05 from "../../images/our-classes/our-classes-5.jpg";
import imgClass06 from "../../images/our-classes/our-classes-6.jpg";

export default () => {
  const [show, setShow] = useState("");

  const handleClose = () => setShow("");
  const handleShow = idModal => setShow(idModal);

  return (
    <section className="our_class section_padding">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-7">
            <div className="section_title text-center">
              <h2>Music School</h2>
            </div>
          </div>
        </div>
        <div className="row mb-md-4 mb-2">
          <div className="col-sm-12 col-md-4">
            <div className="single_feature">
              <ImageBackground src={imgClass01} />
              <div className="single_service">
                <h4>Private</h4>
                <Button
                  className="genric-btn primary circle"
                  variant="primary"
                  onClick={() => handleShow("class_private")}
                >
                  Class detail
                </Button>

                <Modal show={show === "class_private"} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Private Lesson</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Our private lesson is a 30-minute one-on-one instruction,
                      once a week, for students of all ages: piano, keyboard,
                      vocal, violin, viola, cello, contrabass, acoustic guitar,
                      electric guitar, electric bass, flute, clarinet,
                      saxophone, trumpet, drum, music theory &amp; composition.
                    </p>
                    <p>
                      <h6 className="mb-2 mt-3">Tuition fee:</h6>
                      <ul className="unordered-list">
                        <li>
                          <b>Beginner </b>
                          <span className="ml-1">IDR 735,000/month</span>
                        </li>
                        <li>
                          <b>Intermediate </b>
                          <span className="ml-1">IDR 800,000/month</span>
                        </li>
                        <li>
                          <b>Upper Intermediate </b>
                          <span className="ml-1">IDR 900,000/month</span>
                        </li>
                        <li>
                          <b>Advanced </b>
                          <span className="ml-1">IDR 1,050,000/month</span>
                        </li>
                        <li>
                          <b>Expert </b>
                          <span className="ml-1">IDR 1,200,000/month</span>
                        </li>
                        {/* <li>
                          <b>Private piano/vocal lesson with Ivonne Atmojo </b>
                          <div className="ml-1">IDR 875,000/45-min session</div>
                        </li> */}
                      </ul>
                    </p>
                    <p>
                      We offer a 45-minute private piano/vocal lesson with
                      Ivonne Atmojo (B.Mus, University of Wisconsin - Madison
                      M.Mus, San Francisco Conservatory of Music) for IDR
                      875,000/session
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="single_feature">
              <ImageBackground src={imgClass02} />
              <div className="single_service">
                <h4>Group</h4>
                <Button
                  className="genric-btn primary circle"
                  variant="primary"
                  onClick={() => handleShow("class_group")}
                >
                  Class detail
                </Button>

                <Modal show={show === "class_group"} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Group Class</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Our group class is a 45-minute group instruction, once a
                      week, a minimum of 2 students per class: piano, keyboard,
                      vocal, violin, viola, cello, contrabass, acoustic guitar,
                      electric guitar, electric bass, flute, clarinet,
                      saxophone, trumpet, drum, music theory &amp; composition.
                    </p>
                    <p>
                      <h6 className="mb-2 mt-3">Tuition fee:</h6>
                      <ul className="unordered-list">
                        <li>
                          <b>Beginner </b>
                          <span className="ml-1">IDR 635,000/month</span>
                        </li>
                        <li>
                          <b>Intermediate </b>
                          <span className="ml-1">IDR 700,000/month</span>
                        </li>
                        <li>
                          <b>Upper Intermediate </b>
                          <span className="ml-1">IDR 800,000/month</span>
                        </li>
                      </ul>
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="single_feature">
              <ImageBackground src={imgClass03} />
              <div className="single_service">
                <h4>Children</h4>
                <Button
                  className="genric-btn primary circle"
                  variant="primary"
                  onClick={() => handleShow("class_children")}
                >
                  Class detail
                </Button>

                <Modal show={show === "class_children"} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Children Music Class</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="mb-4">
                      <h5 className="mb-2">
                        Tchaikovsky Toddlers (2 - 3.5 years old)
                      </h5>
                      <span>
                        A 45-minute early childhood music education class for
                        children, ages 2-4 years old. In the class, children
                        learn &amp; explore music through singing, dancing, body
                        movements, rhymes, &amp; playing percussion instruments.
                        Children are taught a little bit of everything from
                        melody &amp; rhythm to music theory &amp;history.
                      </span>
                      <ul className="unordered-list">
                        <li>
                          <b>Instructor </b>
                          <span className="ml-1">
                            Rani Seba &amp; Chrisanti Andanawari
                          </span>
                        </li>
                        <li>
                          <b>Tuition fee </b>
                          <span className="ml-1">IDR 650,000/month</span>
                        </li>
                      </ul>
                    </p>
                    <p>
                      <h5 className="mb-2">
                        Music For Little Mozarts (3.5 - 6 years old)
                      </h5>
                      <span>
                        A 45-minute weekly music class for young children, ages
                        4-6, designed to develop singing &amp; listening skills,
                        movements, rhythmic &amp; tonal elements while learning
                        to play piano. Children are taught new musical concepts
                        as they follow the adventure of "Beethoven Bear &amp;
                        Mozart Mouse" using sequential stories, with the
                        addition of plush toys to stimulate the student's
                        imagination.
                      </span>
                      <ul className="unordered-list">
                        <li>
                          <b>Instructor </b>
                          <span className="ml-1">
                            Rani Seba &amp; Cati Rahayu
                          </span>
                        </li>
                        <li>
                          <b>Tuition fee </b>
                          <span className="ml-1">IDR 650,000/month</span>
                        </li>
                      </ul>
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-md-4 mb-2">
          <div className="col-sm-12 col-md-4">
            <div className="single_feature">
              <ImageBackground src={imgClass04} />
              <div className="single_service">
                {/* <span>04</span> */}
                <h4>Choir</h4>
                <Button
                  className="genric-btn primary circle"
                  variant="primary"
                  onClick={() => handleShow("class_choir")}
                >
                  Class detail
                </Button>

                <Modal show={show === "class_choir"} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Choir</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="mb-4">
                      <h5 className="mb-2">Broadway Baby Choir</h5>
                      <span>A children choir for ages 5 - 12 years old.</span>
                      <ul className="unordered-list">
                        <li>
                          <b>Repertoire </b>
                          <span className="ml-1">
                            Disney, Broadway, &amp; Pop
                          </span>
                        </li>
                        <li>
                          <b>Instructor </b>
                          <span className="ml-1">Cati Rahayu</span>
                        </li>
                        <li>
                          <b>Schedule </b>
                          <span className="ml-1">Saturday, 12:30 - 1:45pm</span>
                        </li>
                        <li>
                          <b>Tuition fee </b>
                          <span className="ml-1">IDR 485,000/month</span>
                        </li>
                      </ul>
                    </p>
                    <p>
                      <h5 className="mb-2">Women's Choir</h5>
                      <span>
                        A female choir for just about anyone who can carry a
                        tune and enjoys singing.
                      </span>
                      <ul className="unordered-list">
                        <li>
                          <b>Repertoire </b>
                          <span className="ml-1">Pop &amp; Jazz</span>
                        </li>
                        <li>
                          <b>Instructor </b>
                          <span className="ml-1">Ivonne Atmojo</span>
                        </li>
                        <li>
                          <b>Schedule </b>
                          <span className="ml-1">Saturday, 3:15 - 4:30pm</span>
                        </li>
                        <li>
                          <b>Tuition fee </b>
                          <span className="ml-1">IDR 485,000/month</span>
                        </li>
                      </ul>
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="single_feature">
              <ImageBackground src={imgClass05} />
              <div className="single_service">
                <h4>Band &amp; Ensemble</h4>
                <Button
                  className="genric-btn primary circle"
                  variant="primary"
                  onClick={() => handleShow("class_band")}
                >
                  Class detail
                </Button>

                <Modal show={show === "class_band"} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Band &amp; Ensemble</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="mb-4">
                      <h5 className="mb-2">Band</h5>
                      <ul className="unordered-list">
                        <li>
                          <b>Genre </b>
                          <span className="ml-1">Pop, Rock, or Jazz</span>
                        </li>
                        <li>
                          <b>Schedule </b>
                          <span className="ml-1">every other week</span>
                        </li>
                        <li>
                          <b>Tuition fee </b>
                          <span className="ml-1">IDR 485,000/month</span>
                        </li>
                      </ul>
                    </p>
                    <p>
                      <h5 className="mb-2">String Chamber</h5>
                      <span>
                        An ensemble of any combinations of string instruments.
                      </span>
                      <ul className="unordered-list">
                        <li>
                          <b>Repertoire </b>
                          <span className="ml-1">
                            Classical &amp; Movie music
                          </span>
                        </li>
                        <li>
                          <b>Schedule </b>
                          <span className="ml-1">every other week</span>
                        </li>
                        <li>
                          <b>Tuition fee </b>
                          <span className="ml-1">IDR 485,000/month</span>
                        </li>
                      </ul>
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="single_feature">
              <ImageBackground src={imgClass06} />
              <div className="single_service">
                <h4>Digital Music</h4>
                <Button
                  className="genric-btn primary circle"
                  variant="primary"
                  onClick={() => handleShow("class_digital")}
                >
                  Class detail
                </Button>

                <Modal show={show === "class_digital"} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Digital Music</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="mb-4">
                      <h5 className="mb-2">
                        Create Cover &amp; Record Your Favorite Songs
                      </h5>
                      <span>Learn how to cover songs using Garage Band</span>
                      <ul className="unordered-list">
                        <li>
                          <b>Material covered </b>
                          <span className="ml-1">Garage Band</span>
                        </li>
                        <li>
                          <b>Requirement </b>
                          <span className="ml-1">MacBook Pro</span>
                        </li>
                      </ul>
                    </p>
                    <p className="mb-4">
                      <h5 className="mb-2">
                        Pop Music Arrangement &amp; Production
                      </h5>
                      <span>
                        Learn pop music arrangement, audio editing &amp; mixing,
                        and MIDI programming
                      </span>
                      <ul className="unordered-list">
                        <li>
                          <b>Material covered </b>
                          <span className="ml-1">
                            Intermediate Cubase or Logic
                          </span>
                        </li>
                        <li>
                          <b>Requirement </b>
                          <span className="ml-1">
                            Cubase or Logic on your laptop
                          </span>
                        </li>
                      </ul>
                    </p>
                    <p>
                      <h5 className="mb-2">
                        Music For Films &amp; Other Visual Media
                      </h5>
                      <span>
                        Learn orchestration, sound sampling, sound design, music
                        for visual media (commercial, film, etc)
                      </span>
                      <ul className="unordered-list">
                        <li>
                          <b>Material covered </b>
                          <span className="ml-1">
                            Advanced Cubase or Logic, Sibelius
                          </span>
                        </li>
                        <li>
                          <b>Requirement </b>
                          <span className="ml-1">
                            Cubase or Logic on your laptop
                          </span>
                        </li>
                      </ul>
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
